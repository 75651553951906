import React from 'react';
import "./Icons.scss";
import { TbTruckDelivery } from "react-icons/tb";
import { FaRecycle } from "react-icons/fa";
import { RiSecurePaymentFill } from "react-icons/ri";
import { AiOutlineSolution } from "react-icons/ai";

const Icons = () => {
    return (
        <div className="main-headers">
            <div className="header-contents">
                <div className="center">
                <TbTruckDelivery/>
                <RiSecurePaymentFill/>
                <FaRecycle/>
                <AiOutlineSolution />
                </div>
            </div>
        </div>
    )
}

export default Icons