export const slidesData = [
    {
        id: "001",
        img: "https://",
        title: "Finde Premium Products",
        cta: "SHOP NOW",
    },
    {
        id: "002",
        img: "https://cdn.shopify.com/s/files/1/0130/5041/3114/files/home1_02_4c388999-0b9a-4a73-9fe7-1ca465ec8140_x1024.png",
        title: "Get all the Accessories you need",
        cta: "SHOP NOW",
    },
    {
        id: "003",
        img: "https://hongo.b-cdn.net/wp-content/uploads/2019/11/home-page-fashion-slider-01.jpg",
        title: "Wide  range of colors",
        cta: "SHOP NOW",
    },
];
