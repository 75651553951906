import Banner from "./Banner/Banner";
import Category from "../../components/Category/Category";
import Icons from "../Icons/Icons";
import { slidesData } from "../../data/slidesData";
import "./Home.scss";

const Home = () => {
    return (
        <div >
            <Banner slides={slidesData}/>
            <Icons />
            <div className="main-content">
                <div className="layout">
                    <Category />
                </div>
            </div>
            

        </div>
    )
};

export default Home;
