import "./Banner.scss";
import React, { useEffect, useState } from "react";
import home1 from "../../../assets/banner/home1.jpg";
import home2 from "../../../assets/banner/home2.jpg";

const Banner = () => {
  const slides = [
    <div className="container">
      <img src={home1} alt="" />
    </div>,
    <div className="container">
      <img src={home2} alt="" />
    </div>,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 5000); // Change slide every 3 seconds

    return () => {
      clearInterval(timer);
    };
  }, []);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };


  return (
    <div className="slider">
      <div
        className="container"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: 'transform 0.1s ease-in-out',
        }}
      >
        {slides.map((slide, index) => (
          <div key={index}>{slide}</div>
        ))}
      </div>
      {/* <button onClick={prevSlide}>Prev</button>
      <button onClick={nextSlide}>Next</button> */}
    </div>
  )
};

export default Banner;


{/* <div className="slider">
<div className="container" >
  <img src={home1[currentSlide]} alt="" />
</div>
</div> */}